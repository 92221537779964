import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TickerComponent} from './components/ticker/ticker.component';
import {HttpClientModule} from '@angular/common/http';
import {APP_BASE_HREF} from '@angular/common';

@NgModule({
  declarations: [AppComponent, TickerComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [{provide: APP_BASE_HREF, useValue: '/app'}],
  bootstrap: [AppComponent],
})
export class AppModule {
}
