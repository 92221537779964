import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-ticker-component',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.css'],
  styles: []
})
export class TickerComponent implements OnInit {
  _data: {} = {
    'smi1': [],
    'spi1': [],
    'smi2': [],
    'spi2': [],
  };
  scrollerIds: {} = {
    'smi': [
      'scroller-block-smi-primary',
      'scroller-block-smi-secondary'
    ],
    'spi': [
      'scroller-block-spi-primary',
      'scroller-block-spi-secondary'
    ]
  };
  smi_pre_url = '/itf/fqs/delayed/snap.json?select=ValorId,TradingCurrency,ValorSymbol,ClosingPrice,ClosingPerformance,ClosingDelta,ShortName&where=ValorId=CH0000222130CHF9+ValorId@CH0000222130CHF9+ValorId=CH0009980894CHF9&pagesize=999';
  spi_pre_url = '/itf/fqs/delayed/snap.json?select=ValorId,TradingCurrency,ValorSymbol,ClosingPrice,ClosingPerformance,ClosingDelta,ShortName&where=ValorId=CH0009987501CHF9+ValorId@CH0009987501CHF9&pagesize=999';
  smi_url: string;
  spi_url: string;
  pointer_up: string = '▲';
  pointer_right: string = '►';
  pointer_down: string = '▼';
  smiSpeed: number = 200;
  spiSpeed: number = 800;
  showCurrency: boolean = false;
  index: string = 'both';
  textSize: string = 'm';
  actualFontGroup: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) {
    let url = '';
    this.smi_url = url + this.smi_pre_url;
    this.spi_url = url + this.spi_pre_url;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['showCurrency'] != undefined) {
        this.showCurrency = (/true/i).test(params['showCurrency']);
      }
      if (params['textSize'] != undefined) {
        this.textSize = params['textSize'];
      }
    });
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data;
  }

  setFontSize() {
    this.actualFontGroup = 'size-' + this.textSize;
  }

  ngOnInit() {
    this.renewData();
    this.setFontSize();
    this.renewalInterval();
  }

  renewalInterval() {
    setTimeout(() => {
      this.renewData();
      this.renewalInterval();
    }, 10000);
  }

  renewData() {
    this.http.get(this.smi_url, this.httpOptions).subscribe(data => {
      let newData = this.data;
      if (this.shouldUpdateData('smi1')) {
        newData['smi1'] = data['rowData'];
      }
      if (this.shouldUpdateData('smi2')) {
        newData['smi2'] = data['rowData'];
      }
      this.data = newData;
      this.setFontSizeForTicker();
    });
    this.http.get(this.spi_url, this.httpOptions).subscribe(data => {
      let newData = this.data;
      if (this.shouldUpdateData('spi1')) {
        newData['spi1'] = data['rowData'];
      }
      if (this.shouldUpdateData('spi2')) {
        newData['spi2'] = data['rowData'];
      }
      this.data = newData;
      this.setFontSizeForTicker();
    });
  }

  shouldUpdateData(tickerElement) {
    let element = document.getElementById('tickerContainer_' + tickerElement);
    return this.isElementInViewport(element);
  }

  //Source: https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
  isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  setFontSizeForTicker() {
    let elements = document.getElementsByClassName('sizeAdjustable');
    for (var i = 0; i < elements.length; i++) {
      let element = elements.item(i);
      element.setAttribute('class', 'sizeAdjustable ' + this.actualFontGroup);
    }
  }
}
